.hero_unused {
  background: radial-gradient(
        61.96% 61.98% at 21.03% 28.42%,
        rgba(236, 168, 100, 0.3) 0%,
        rgba(230, 186, 142, 0.3) 67.77%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        67.01% 65.38% at 74.93% 25.97%,
        rgba(236, 206, 98, 0.3) 0%,
        rgba(233, 213, 144, 0.3) 72.63%,
        rgba(217, 217, 217, 0) 99.48%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.hero::before {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 111, 80, 0.21) 0,
    rgba(255, 190, 92, 0.21) 50%,
    rgba(255, 238, 176, 0.21) 100%
  );
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  filter: blur(60px);
  height: 86%;
  position: absolute;
  top: -165px;
  width: 100%;
  z-index: -9;
}
.features_section {
  background: radial-gradient(
        96.6% 125.32% at 96.53% 71.34%,
        rgba(228, 207, 164, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        149.9% 57.37% at 29.23% 88.19%,
        #ffeedd 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.business_tools {
  background: radial-gradient(
        83.06% 83.06% at 91.01% 16.94%,
        rgba(230, 143, 41, 0.2) 0%,
        rgba(239, 142, 53, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        74.93% 74.93% at 10.81% 29.31%,
        #feecc7 0%,
        rgba(254, 241, 216, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.join_us {
  background: radial-gradient(
        63.07% 63.07% at 88.1% 63.07%,
        #ffe6b5 0%,
        rgba(251, 243, 229, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        77.08% 80.98% at 24.44% 80.98%,
        #fedebc 0%,
        #fedebc 0.01%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
